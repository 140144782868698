.dummy[data-v-6eebb077] {
  height: 100%;
  background: rgb(217, 217, 217);
  background: radial-gradient(circle, rgba(217, 217, 217, 1) 0%, rgba(251, 251, 251, 1) 100%);
}
.background[data-v-6eebb077] {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(5px);
}
