.contacts-container[data-v-6beaa74f] {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}
.contact-container[data-v-6beaa74f] {
  flex: 1 1 255px;
  display: flex;
  flex-direction: column;
  max-width: 255px;
  border-radius: 1em;
}
.contact-container[data-v-6beaa74f] .primary {
  background-color: #ffd60224;
}
.contact-container[data-v-6beaa74f] .ant-card {
  border-radius: 1em;
}
.contact-container[data-v-6beaa74f] .ant-card-body {
  flex-grow: 1;
}
.contact-container[data-v-6beaa74f] .ant-card-actions {
  background-color: transparent;
}
.add-contact[data-v-6beaa74f] {
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-contact[data-v-6beaa74f] .anticon {
  font-size: 5em;
}
.add-contact[data-v-6beaa74f] .ant-card-body {
  flex-grow: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
