.flex[data-v-d18661a2] {
  display: flex;
}
.flex.inline[data-v-d18661a2] {
  display: inline-flex;
}

/* Direction */
.flex.vertical[data-v-d18661a2] {
  flex-direction: column;
}

/* Center */
.flex.center-v[data-v-d18661a2] {
  align-items: center;
}
.flex.center-h[data-v-d18661a2] {
  justify-content: center;
}

/* Align | Vertical */
.flex.horizontal.align-right[data-v-d18661a2]  { justify-content: flex-end;
}
.flex.horizontal.align-left[data-v-d18661a2]   { justify-content: flex-start;
}
.flex.horizontal.align-top[data-v-d18661a2]    { align-items:     flex-start;
}
.flex.horizontal.align-bottom[data-v-d18661a2] { align-items:     flex-end;
}
.flex.vertical.align-right[data-v-d18661a2]  { align-items:     flex-end;
}
.flex.vertical.align-left[data-v-d18661a2]   { align-items:     flex-start;
}
.flex.vertical.align-top[data-v-d18661a2]    { justify-content: flex-start;
}
.flex.vertical.align-bottom[data-v-d18661a2] { justify-content: flex-end;
}

/*
.flex.align-right {
  justify-content: flex-end;
}

.flex.align-top {
  align-items: flex-start;
}

.flex.align-bottom {
  align-items: flex-end;
}

.flex.align-left {
  justify-content: flex-start;
} */

/* Space between */
.flex.space-between-v[data-v-d18661a2] {
  align-content: space-between;
}
.flex.space-between-h[data-v-d18661a2] {
  justify-content: space-between;
}

/* Space arround */
.flex.space-arround-v[data-v-d18661a2] {
  align-content: space-arround;
}
.flex.space-arround-h[data-v-d18661a2] {
  justify-content: space-arround;
}
.flex.wrap[data-v-d18661a2] {
  flex-wrap: wrap;
}
