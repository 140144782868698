#timeline[data-v-6cb0dbf5] {
  padding-top: 2em;
}
#timeline[data-v-6cb0dbf5] .ant-timeline-item-right .ant-timeline-item-content>.flex {
  align-items: flex-end;
}
#timeline[data-v-6cb0dbf5] .attachments-divider {
  margin-bottom: 0;
  margin-top: 1em;
  color: lightgray;
}
#timeline .entry[data-v-6cb0dbf5] .ant-card-body {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
#timeline .entry.empty[data-v-6cb0dbf5] .ant-card-body {
  display: none;
}
#timeline[data-v-6cb0dbf5] .ant-card {
  border-radius: 8px;
}
#timeline .entry .title[data-v-6cb0dbf5] {
  white-space: normal;
}
#timeline[data-v-6cb0dbf5] .ant-tag {
  margin-right: 0 !important;
}
#timeline .event .avatar[data-v-6cb0dbf5], #timeline .entry .avatar[data-v-6cb0dbf5] {
  margin: 0px 6px;
}
