.header[data-v-8fcb9870] {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
.content[data-v-8fcb9870] {
  overflow: auto;
}
.content>div[data-v-8fcb9870] {
  margin: 24px 16px 0;
  overflow: visible;
  overflow: initial;
}
.ant-layout[data-v-8fcb9870] {
  height: 100%;
}
.ant-layout[data-v-8fcb9870] .ant-layout-sider-children {
  display: flex;
}
.menu[data-v-8fcb9870] {
  flex: 1 1;
  /* margin: 64px 0 0 0 !important */
}
.account[data-v-8fcb9870] {
  color: white;
  height: 100%;
  line-height: 1em;
}
.account #username[data-v-8fcb9870] {
  font-size: 1.5em;
}
.account #email[data-v-8fcb9870] {
  font-size: 0.75em;
}
.account .anticon[data-v-8fcb9870] {
  font-size: 2em;
}
#last-auth[data-v-8fcb9870] {
  font-size: 0.75em;
}
#logout[data-v-8fcb9870] {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
}
.logo[data-v-8fcb9870] {
  height: 64px;
  object-fit: contain;
  width: 100%;
}
.version[data-v-8fcb9870] {
  margin: auto;
  color: white;
}
.sider[data-v-8fcb9870] {
  width: 100%;
}
