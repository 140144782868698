.firmwares[data-v-8f4669b4] .unavailable td:not(:last-child) {
  opacity: 0.5 !important;
}
.firmwares[data-v-8f4669b4] .unavailable td {
  background-color: rgb(250 250 250);
}
.firmwares[data-v-8f4669b4] .query .help {
  opacity: 0.5 !important;
  font-size: 0.75em;
  width: 50%;
  margin: auto;
}
