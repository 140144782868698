.logo[data-v-148c42b5] {
  position: absolute;
  top: -12em;
  height: 10em;
}
.logo img[data-v-148c42b5] {
  height: 100%;
}
.logo .subtitle[data-v-148c42b5] {
  position: absolute;
  bottom: 0;
  right: 0;
  color: white;
  font-size: 1.25em;
}
