.breadcrumb .item, .breadcrumb .item>a {
  color: rgba(255, 255, 255, 0.65) !important;
}

.breadcrumb li:last-child .item {
  color: white !important
}

.ant-breadcrumb-separator {
  color: rgba(255, 255, 255, 0.5) !important;
}
