#root {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.ui.yellow.header {
  background-color: black;
}

.ui.yellow.button {
  color: rgba(0,0,0,.6);
}

.ui.yellow.button:hover {
  color: rgba(0,0,0,.8);
}

#content {
  position: absolute;
  left: 0;
  right: 0;
  background-color: #f7f7f7;
  overflow-y: scroll;
  height: 100%;
  padding-left: 1.75em;
  padding-right: 1.75em;
}

#content .container {
  margin-top: 1.75em;
}

#content .container:last-child {
  margin-bottom: 1.75em;
}

.mono {
  font-family: monospace;
}

.container-grid {
  display: flex !important;
}

.container-grid>* {
  flex: 1 1;
}

.container-grid>*:not(:first-child) {
  margin-left: 1.75em;
}

.contract-infos {
  display: flex;
  align-items: center;
}

.flex .ant-input-search {
  max-width: 25em;
}

.anticon.inline {
  margin: 0 !important;
}

.ant-avatar-circle {
  color: #ffffff !important;
  background-color: #f7c700 !important;
  font-weight: bold;
}

.ant-layout-sider, .ant-layout-header {
  background-color: #0b2939 !important;
}

.ant-btn-primary:not([disabled]) {
  background-color: #025274 !important;
  border-color: #00405B !important;
}

.ant-switch-checked {
  background-color: #FFC700 !important;
}

.equipment-errors .ant-tooltip-inner {
  padding: 0 !important;
  background: red;
}
