.version[data-v-7d1210cc] {
  text-align: center;
}
.version.discrete[data-v-7d1210cc] {
  opacity: 0.25;
}
.version-hash[data-v-7d1210cc] {
  font-size: 0.5em;
  opacity: 0.75;
}
