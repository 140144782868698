.simulators[data-v-9b726b6b] .to-associate .ant-checkbox .ant-checkbox-inner {
  background-color: rgb(0, 255, 0);
  border-color: rgb(0 207 14) !important;
}
.simulators[data-v-9b726b6b] .to-dissociate .ant-checkbox .ant-checkbox-inner {
  border-color: rgb(207, 0, 0) !important;
}
.simulators[data-v-9b726b6b] tr:not(.to-dissociate, .to-associate) .ant-checkbox-checked .ant-checkbox-inner {
  border-color: rgb(207, 0, 0) !important;
  background-color: rgb(255, 0, 0) !important;
}
.simulators[data-v-9b726b6b] tr:not(.to-dissociate, .to-associate) .ant-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  transform: scale(1) translate(25%, -80%) rotate(0deg);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
