body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#watermark {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0.25em;
  font-size: 3em;
  letter-spacing: 0.15em;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  -webkit-user-select: none;
          user-select: none;
  font-family: monospace;
  line-height: 75%;
  text-transform: uppercase;
  color: #bdbec1;

  & span {
    font-size: 0.5em;
    letter-spacing: normal;
  }
}

iframe#webpack-dev-server-client-overlay {
  display: none !important
}
